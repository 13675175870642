import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconSequence = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 118 0zm.575 4c-1.096 0-1.873.07-2.427.165V12h1.78V9.148c.165.036.377.048.612.048 1.06 0 1.968-.27 2.58-.837.483-.436.73-1.107.73-1.885s-.33-1.437-.85-1.84C10.473 4.212 9.683 4 8.575 4zm.082 1.367c.908 0 1.414.436 1.414 1.166 0 .802-.59 1.284-1.543 1.284-.27 0-.447-.012-.6-.047V5.426c.128-.036.376-.06.73-.06z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconSequence;
