import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgMoon = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.869 9c0-3.545 1.809-6.592 4.402-7.945A6.914 6.914 0 008.415 1C4.32 1 1 4.581 1 9c0 4.418 3.32 8 7.415 8 .29 0 .575-.021.856-.055C6.678 15.591 4.869 12.544 4.869 9z"
        stroke={color}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
});
export default SvgMoon;
