import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconUserStats = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M16 14v2H0v-2h16zM9.25 3.988V13h-2.5V3.988h2.5zm-4.5 1.594V13h-2.5V5.582h2.5zm9-5.583v13h-2.5v-13h2.5z"
        fill="#1c1c1c"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconUserStats;
