import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgTrunk = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 78 58"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1 69h80v-80H-1z" />
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}>
          <path d="M55.522 49.272c0 3.733 2.959 6.758 6.609 6.758 3.653 0 6.608-3.025 6.608-6.758 0-3.729-2.955-6.757-6.608-6.757-3.65 0-6.61 3.028-6.61 6.757zm-46.261 0c0 3.733 2.959 6.758 6.608 6.758 3.653 0 6.609-3.025 6.609-6.758 0-3.729-2.956-6.757-6.61-6.757-3.648 0-6.607 3.028-6.607 6.757z" />
          <path d="M68.739 49.272H77V30.689l-6.609-6.757-8.26-15.204h-14.87V1.97H1v47.302h8.261M55.522 49.272H22.479" />
          <path d="M53.869 15.485h4.957l4.956 10.137M47.261 8.728v33.787M77 42.515H1M24.131 20.807l-10.759-4.781v12.433l10.759 4.784z" />
          <path d="M24.131 20.807l10.758-4.781v12.433l-10.758 4.784zM13.372 16.026l10.759-4.783 10.758 4.783M19.037 13.506l10.341 4.939" />
        </g>
      </g>
    </svg>
  );
});
export default SvgTrunk;
