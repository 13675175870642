import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgMastercard = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 26 18"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={25}
          height={17}
          x={0.5}
          y={0.5}
          fill="#F4F4F4"
          fillRule="nonzero"
          stroke="#E4E5E6"
          rx={2}
        />
        <g transform="translate(4 4)">
          <circle cx={5.5} cy={5} r={5} fill="red" />
          <circle cx={12.5} cy={5} r={5} fill="#FF9A00" />
          <path
            fill="#FF5000"
            d="M9.001 1.43A4.985 4.985 0 0110.5 5 4.985 4.985 0 019 8.57 4.985 4.985 0 017.5 5c0-1.38.56-2.63 1.464-3.536z"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgMastercard;
