import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconDotModalClose = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fillRule="evenodd"
      {...props}>
      <path
        d="M16 32c8.836 0 16-7.164 16-16S24.836 0 16 0 0 7.163 0 16s7.164 16 16 16"
        fill="#1c1c1c"
        opacity={0.1}
      />
      <path
        fill="#fff"
        d="M23.174 10.24L21.76 8.826 16 14.587l-5.76-5.76-1.414 1.414 5.76 5.76-5.76 5.76 1.414 1.414 5.76-5.76 5.76 5.76 1.414-1.414-5.76-5.76z"
      />
    </svg>
  );
});
export default SvgIconDotModalClose;
