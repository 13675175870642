import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgChain = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <g fill={color} fillRule="evenodd">
        <path d="M21.282 2.718a5.867 5.867 0 00-8.297 0L9.712 5.99a7.558 7.558 0 013.18.345l1.856-1.854a3.348 3.348 0 012.386-.989c.9 0 1.748.351 2.384.99.638.635.99 1.481.99 2.383 0 .902-.352 1.75-.99 2.385l-3.629 3.63a3.347 3.347 0 01-2.385.988c-.903 0-1.748-.35-2.386-.988a3.334 3.334 0 01-.719-1.06c-.413.022-.8.194-1.096.49l-.966.968a5.867 5.867 0 009.315 1.366l3.63-3.63a5.867 5.867 0 000-8.296" />
        <path d="M13.543 18.048c-.83 0-1.644-.134-2.424-.398l-1.867 1.867a3.347 3.347 0 01-2.385.988c-.901 0-1.747-.35-2.385-.988a3.35 3.35 0 01-.988-2.385c0-.9.35-1.748.988-2.386l3.629-3.629a3.358 3.358 0 012.385-.987c.902 0 1.748.351 2.386.987.31.31.553.67.72 1.062a1.667 1.667 0 001.097-.49l.966-.967a5.867 5.867 0 00-9.316-1.368l-3.63 3.631a5.866 5.866 0 108.296 8.296l3.268-3.268c-.244.023-.49.035-.74.035z" />
      </g>
    </svg>
  );
});
export default SvgChain;
