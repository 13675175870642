import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLetter = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g stroke={color} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M19 11.95c0 .745-.576 1.35-1.286 1.35H2.286C1.576 13.3 1 12.695 1 11.95v-9.9C1 1.304 1.576.7 2.286.7h15.428C18.424.7 19 1.304 19 2.05v9.9z" />
        <path d="M18.571 1.15l-8.57 7.2-8.573-7.2" />
      </g>
    </svg>
  );
});
export default SvgLetter;
