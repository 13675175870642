import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgStarHalf = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          id="star-half_svg__a"
          d="M8.53 0L5.894 5.266 0 6.111l4.265 4.1L3.26 16l5.271-2.734L13.802 16l-1.006-5.789 4.265-4.099-5.895-.846L8.531 0z"
        />
      </defs>
      <g transform="translate(.272)" fill="none" fillRule="evenodd">
        <mask id="star-half_svg__b" fill="#fff">
          <use xlinkHref="#star-half_svg__a" />
        </mask>
        <path
          stroke={color}
          d="M8.53 1.117L6.225 5.724l-5.139.737 3.719 3.574-.88 5.057 4.606-2.389 4.607 2.389-.88-5.057 3.719-3.574-5.14-.737-2.305-4.607z"
        />
        <path
          fill={color}
          fillRule="nonzero"
          mask="url(#star-half_svg__b)"
          d="M0 0h8.53v16H0z"
        />
      </g>
    </svg>
  );
});
export default SvgStarHalf;
