import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLogo = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 120 27"
      {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M22.076 9.112c-.169-3.557-3.701-4.931-6.419-4.931-5.162 0-9.95 2.712-9.95 9.334 0 4.72 2.444 9.334 9.984 9.334 2.14 0 4.008-.282 6.725-1.163v-4.86h-7.845V12.88h13.211v11.87c-3.498 1.549-8.864 2.253-12.43 2.253C6.216 27.004 0 22.778 0 13.55 0 5.942 5.197.236 14.842.236c7.404 0 12.94 2.924 12.94 8.876h-5.706m20.57 9.158c-3.906 0-6.114.845-6.114 2.958 0 1.197.951 2.818 3.906 2.818 2.548 0 6.385-1.55 6.454-5.776h-4.246zm9.408 4.719c0 1.162.034 2.149.136 3.346h-4.755c-.102-.81-.136-1.62-.136-2.43-2.14 2.219-4.789 3.1-8.083 3.1-5.978 0-8.05-2.889-8.05-5.425 0-5.53 5.095-6.48 11.616-6.48h4.11c0-3.135-2.14-3.945-4.721-3.945-2.514 0-3.94 1.092-4.144 2.465H32.66c.51-4.544 5.231-5.635 9.647-5.635 4.788 0 9.747 1.408 9.747 7.608v7.396zm4.26-10.144c0-1.55-.068-3.064-.136-4.191h4.96c.067.986.135 1.937.135 2.923h.068c1.732-2.5 4.075-3.592 7.2-3.592 3.057 0 7.812 1.725 7.812 7.467v10.883H71.19v-9.369c0-4.367-1.936-5.389-4.416-5.389-2.683 0-5.298 1.938-5.298 6.235v8.523h-5.163v-13.49m31.655-9.58v5.389h6.08v3.381h-6.08v8.242c0 1.938.782 2.994 2.751 2.994 1.12 0 2.48-.106 3.193-.317v3.381c-1.393.176-2.955.317-4.381.317-5.163 0-6.725-1.726-6.725-5.881v-8.736h-4.755V8.654h4.755v-3.77l5.162-1.619m9.409 9.827c0-1.69-.068-3.24-.136-4.438h4.959c.068 1.092.136 2.148.136 3.24h.068c.509-1.197 1.664-3.592 5.637-3.592 1.087 0 2.106.106 3.329.352v4.015c-.306-.07-.68-.105-1.087-.14-.374-.036-.815-.071-1.324-.071-4.178 0-6.42 1.867-6.42 6.727v7.15h-5.162V13.092m17.186 13.243h5.163V8.654h-5.163v17.681zm-.272-21.133H120V.905h-5.707v4.297z"
      />
    </svg>
  );
});
export default SvgLogo;
