import React, { forwardRef } from 'react';
import { IconProps } from 'core/icon/icon.props';
import { StyledSvgIcon } from 'core/icon/icon.styles';
import { IconPresets } from 'core/icon/icon.presets';
import startCase from 'lodash/startCase';

export const Icon = forwardRef((props: IconProps, ref) => {
  const { name, width, height, ...others } = Object.assign(
    {},
    IconPresets,
    props,
  );

  const fileName = startCase(name).replace(/ /g, '');

  const svgIcon = require(`./generated/${fileName}.tsx`).default;

  return (
    <StyledSvgIcon as={svgIcon} nwidth={width} nheight={height} {...others} />
  );
});
