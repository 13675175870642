import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgPinterest = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19 24"
      {...props}>
      <path
        fill="#CC2127"
        fillRule="evenodd"
        d="M.667 8.706c0 2.305.886 4.356 2.786 5.12.311.125.59.005.68-.335.063-.235.212-.829.278-1.076.091-.336.056-.454-.196-.747-.547-.637-.897-1.46-.897-2.629 0-3.386 2.571-6.419 6.697-6.419 3.653 0 5.66 2.2 5.66 5.137 0 3.864-1.736 7.126-4.312 7.126-1.424 0-2.489-1.16-2.147-2.582.408-1.698 1.2-3.53 1.2-4.756 0-1.097-.598-2.012-1.834-2.012-1.454 0-2.624 1.483-2.624 3.469 0 1.265.434 2.12.434 2.12l-1.75 7.304c-.519 2.168-.077 4.825-.04 5.093.022.159.229.197.323.077.135-.173 1.866-2.28 2.457-4.386.166-.596.956-3.683.956-3.683.473.888 1.854 1.67 3.322 1.67 4.374 0 7.34-3.928 7.34-9.186C19 4.036 15.582.333 10.388.333 3.926.333.667 4.9.667 8.706z"
      />
    </svg>
  );
});
export default SvgPinterest;
