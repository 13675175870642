import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgGiftTeal = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g stroke={color} strokeWidth={2.316} fill="none" fillRule="evenodd">
        <path d="M1.695 21.816h43.073v-7.547H1.695zM3.568 44.456h39.327v-22.64H3.568zM16.677 14.27v30.187M29.786 44.456V14.27" />
        <path
          d="M33.827 10.06c-1.829 1.84-10.595 4.21-10.595 4.21s2.143-9.04 3.971-10.881a4.66 4.66 0 016.624 0 4.747 4.747 0 010 6.67zM12.637 10.06c1.829 1.84 10.595 4.21 10.595 4.21s-2.145-9.04-3.973-10.881a4.658 4.658 0 00-6.622 0 4.743 4.743 0 000 6.67z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
export default SvgGiftTeal;
