import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgMenu = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g
        stroke={color}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        opacity={0.5}>
        <path d="M0 1h19M0 8h19" />
      </g>
    </svg>
  );
});
export default SvgMenu;
