import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgTwitterTransparent = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      {...props}>
      <path
        fill="#00AAEC"
        fillRule="evenodd"
        d="M20 2.64a8.015 8.015 0 01-2.356.662A4.198 4.198 0 0019.448.975a8.081 8.081 0 01-2.607 1.02A4.047 4.047 0 0013.846.666c-2.264 0-4.101 1.884-4.101 4.207 0 .33.035.65.105.958-3.41-.176-6.433-1.849-8.458-4.397a4.284 4.284 0 00-.555 2.117c0 1.46.724 2.747 1.825 3.502A4.029 4.029 0 01.803 6.53v.052c0 2.04 1.415 3.74 3.293 4.125a3.913 3.913 0 01-1.081.148c-.265 0-.523-.025-.773-.074.523 1.67 2.037 2.888 3.833 2.92A8.102 8.102 0 010 15.445a11.424 11.424 0 006.29 1.888c7.548 0 11.674-6.41 11.674-11.97 0-.183-.003-.366-.01-.546A8.421 8.421 0 0020 2.64"
      />
    </svg>
  );
});
export default SvgTwitterTransparent;
