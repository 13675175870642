import styled, { css } from 'styled-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';

export const StyledSvgIcon = styled.svg<any>`
  ${({ size, nwidth, nheight, cursor, top, bottom, right, left }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: nwidth ?? size,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: nheight ?? size,
      },
    ];

    return css`
      ${generateStylesForResolutionAwareProps(entries)};
      cursor: ${cursor};
      position: relative;
      top: ${top || 'unset'};
      bottom: ${bottom || 'unset'};
      right: ${right || 'unset'};
      left: ${left || 'unset'};
    `;
  }}
`;
