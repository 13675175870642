import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgReach = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 200 200"
      fillRule="evenodd"
      {...props}>
      <circle fill="#f9c910" fillRule="nonzero" cx={100} cy={100} r={100} />
      <path
        d="M102.707 74.27v54.575c0 5.996 2.83 8.66 8.493 8.66h4.996v3.497h-47.96v-3.497h2.665c5.66 0 8.492-2.664 8.492-8.66V78.953c0-8.66-2.662-11.825-10-11.825h-1.997v-3.164l15.182-4.61c10.014-3.04 20.12 4.453 20.12 14.918zm20.672-15.864c9.742 0 17.638 7.707 17.638 17.214H105.74c0-9.507 7.9-17.214 17.638-17.214z"
        fill="#fbf2ef"
      />
    </svg>
  );
});
export default SvgReach;
