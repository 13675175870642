import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconDownload = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M16 14v2H0v-2h16zM9.066 0v7.9l2.868-2.868 1.414 1.414-5.282 5.282-5.282-5.282 1.414-1.414L7.066 7.9V0h2z"
        fill="#1b7b76"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconDownload;
