export { default as Svg1YearGuarantee } from './1YearGuarantee';
export { default as Svg30DaysReturn } from './30DaysReturn';
export { default as Address } from './Address';
export { default as AffirmBlue } from './AffirmBlue';
export { default as Affirm } from './Affirm';
export { default as Amex } from './Amex';
export { default as ApplePay } from './ApplePay';
export { default as Ar } from './Ar';
export { default as ArrowDown } from './ArrowDown';
export { default as ArrowLeft } from './ArrowLeft';
export { default as ArrowRight } from './ArrowRight';
export { default as Behance } from './Behance';
export { default as BellTransparent } from './BellTransparent';
export { default as Bell } from './Bell';
export { default as BlankCard } from './BlankCard';
export { default as Box } from './Box';
export { default as Branch } from './Branch';
export { default as Bulb } from './Bulb';
export { default as Calendar } from './Calendar';
export { default as California } from './California';
export { default as CameraSquare } from './CameraSquare';
export { default as Camera } from './Camera';
export { default as Cart } from './Cart';
export { default as Chain } from './Chain';
export { default as CheckFill } from './CheckFill';
export { default as CheckReverse } from './CheckReverse';
export { default as CheckRound } from './CheckRound';
export { default as Check } from './Check';
export { default as ChevronDown } from './ChevronDown';
export { default as ChevronLeft } from './ChevronLeft';
export { default as ChevronRight } from './ChevronRight';
export { default as Clip } from './Clip';
export { default as Close } from './Close';
export { default as DashboardChart } from './DashboardChart';
export { default as Dashboard } from './Dashboard';
export { default as Design } from './Design';
export { default as Dimming } from './Dimming';
export { default as DisabledArrows } from './DisabledArrows';
export { default as DisabledInputArrow } from './DisabledInputArrow';
export { default as Discover } from './Discover';
export { default as Docs } from './Docs';
export { default as Dollar } from './Dollar';
export { default as DotsAnimated } from './DotsAnimated';
export { default as Dots } from './Dots';
export { default as Download } from './Download';
export { default as DragHandler } from './DragHandler';
export { default as Drag } from './Drag';
export { default as EmailRound } from './EmailRound';
export { default as Email } from './Email';
export { default as Error } from './Error';
export { default as FacebookBlack } from './FacebookBlack';
export { default as FacebookTransparent } from './FacebookTransparent';
export { default as FreeShipping } from './FreeShipping';
export { default as GiftCard } from './GiftCard';
export { default as GiftGold } from './GiftGold';
export { default as GiftTeal } from './GiftTeal';
export { default as Gift } from './Gift';
export { default as Google } from './Google';
export { default as Guarantee } from './Guarantee';
export { default as Hand } from './Hand';
export { default as HeartOutlined } from './HeartOutlined';
export { default as Heart } from './Heart';
export { default as Help } from './Help';
export { default as House } from './House';
export { default as IconBell } from './IconBell';
export { default as IconCheck } from './IconCheck';
export { default as IconDotArrow } from './IconDotArrow';
export { default as IconDotModalClose } from './IconDotModalClose';
export { default as IconDownload } from './IconDownload';
export { default as IconInfo } from './IconInfo';
export { default as IconJobsAssemble } from './IconJobsAssemble';
export { default as IconJobsBondo } from './IconJobsBondo';
export { default as IconJobsPack } from './IconJobsPack';
export { default as IconJobsPrint } from './IconJobsPrint';
export { default as IconJobsQa } from './IconJobsQa';
export { default as IconJobsSand } from './IconJobsSand';
export { default as IconMenuClose } from './IconMenuClose';
export { default as IconMenu } from './IconMenu';
export { default as IconMore } from './IconMore';
export { default as IconReorderOff } from './IconReorderOff';
export { default as IconReorderOn } from './IconReorderOn';
export { default as IconSequence } from './IconSequence';
export { default as IconStarFilled } from './IconStarFilled';
export { default as IconStar } from './IconStar';
export { default as IconUpload } from './IconUpload';
export { default as IconUserProfile } from './IconUserProfile';
export { default as IconUserStats } from './IconUserStats';
export { default as Info } from './Info';
export { default as InputArrow } from './InputArrow';
export { default as InstagramColored } from './InstagramColored';
export { default as Instagram } from './Instagram';
export { default as Lamp } from './Lamp';
export { default as Leaf } from './Leaf';
export { default as Led } from './Led';
export { default as LeftQuote } from './LeftQuote';
export { default as Letter } from './Letter';
export { default as Link } from './Link';
export { default as LiveTracking } from './LiveTracking';
export { default as Loader } from './Loader';
export { default as Logo } from './Logo';
export { default as Mastercard } from './Mastercard';
export { default as MenuDown } from './MenuDown';
export { default as Menu } from './Menu';
export { default as MinusCircle } from './MinusCircle';
export { default as Moon } from './Moon';
export { default as NavHelp } from './NavHelp';
export { default as Next } from './Next';
export { default as One } from './One';
export { default as Paint } from './Paint';
export { default as Pencil } from './Pencil';
export { default as PinterestColored } from './PinterestColored';
export { default as Pinterest } from './Pinterest';
export { default as PlayFull } from './PlayFull';
export { default as Play } from './Play';
export { default as PlusCircleBold } from './PlusCircleBold';
export { default as PlusCircle } from './PlusCircle';
export { default as Plus } from './Plus';
export { default as Pointing } from './Pointing';
export { default as Previous } from './Previous';
export { default as Ray } from './Ray';
export { default as ReachLogo } from './ReachLogo';
export { default as Reach } from './Reach';
export { default as Returns } from './Returns';
export { default as RightArrow } from './RightArrow';
export { default as Ruler } from './Ruler';
export { default as Search } from './Search';
export { default as Settings } from './Settings';
export { default as Share } from './Share';
export { default as SprkLogo } from './SprkLogo';
export { default as StarHalf } from './StarHalf';
export { default as StarOutlined } from './StarOutlined';
export { default as Star } from './Star';
export { default as Sun } from './Sun';
export { default as Tip } from './Tip';
export { default as TrashRound } from './TrashRound';
export { default as Trash } from './Trash';
export { default as Trunk } from './Trunk';
export { default as TwitterTransparent } from './TwitterTransparent';
export { default as Twitter } from './Twitter';
export { default as UserSmiling } from './UserSmiling';
export { default as User } from './User';
export { default as Visa } from './Visa';
export { default as Windows } from './Windows';
export { default as Wishlist } from './Wishlist';
export { default as World } from './World';
export { default as Yotpo } from './Yotpo';
