import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconMenuClose = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M19.76 5.616L13.375 12l6.383 6.384-1.375 1.375L12 13.375 5.616 19.76 4.24 18.384 10.625 12 4.24 5.616 5.616 4.24 12 10.624l6.384-6.383 1.375 1.375z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconMenuClose;
