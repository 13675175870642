import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconJobsQa = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M7.093.52s.62-.723 1.03-.465S9.62.572 9.852 1.732s.13 2.84-.077 3.742l-.206.903h4.8s1.754.62 1.625 1.625-.413 1.342-.413 1.342.336.8.207 1.264-.594.826-.594.826.233 1.006 0 1.496-.722.8-.722.8.284.75-.18 1.34c-.44.594-1.3.93-1.3.93H4.6s-1.213-.3-1.368-.773c-.128-.44 0-7.018 0-7.018l.103-.078s3.897-3.7 3.818-5.52L7.092.534zM2.165 8.194V16H0V8.194h2.165z"
        fill="#1c1c1c"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconJobsQa;
