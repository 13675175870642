import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLed = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 80h80V0H0z" />
        <path
          d="M78 40c0 21.135-17.014 38.267-38 38.267C19.013 78.267 2 61.135 2 40 2 18.866 19.013 1.733 40 1.733c20.986 0 38 17.133 38 38.267z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.4 40c0 16.907-13.61 30.613-30.4 30.613C23.211 70.613 9.6 56.907 9.6 40S23.21 9.387 40 9.387c16.79 0 30.4 13.706 30.4 30.613z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.8 40c0 12.68-10.208 22.96-22.8 22.96S17.2 52.68 17.2 40c0-12.68 10.208-22.96 22.8-22.96S62.8 27.32 62.8 40z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill={color}
          d="M25.674 33.394h2.4v9.163h4.71v1.882h-7.11zM35.648 33.379H43v1.806h-4.998v2.63h4.68v1.805h-4.68v2.983h5.165v1.837h-7.52zM48.784 42.619c.26.016.654.03.928.03 1.002 0 1.868-.076 2.551-.764.927-.934.988-2.585.988-3.121 0-1.561-.53-3-2.005-3.412-.486-.138-1.109-.214-2.462-.153v7.42zm-2.385-9.21l3.632.016c.607 0 2.46.015 3.783.994 1.381 1.025 1.867 2.769 1.867 4.345 0 3.396-1.806 5.17-3.888 5.553-.409.076-.866.122-1.975.122H46.4v-11.03z"
        />
      </g>
    </svg>
  );
});
export default SvgLed;
