import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgReachLogo = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      viewBox="0 0 200 65"
      {...props}>
      <defs>
        <path id="reach-logo_svg__a" d="M.245.362h36.402V42.5H.245z" />
        <path id="reach-logo_svg__c" d="M.695.362H42.25V42.5H.695z" />
        <path id="reach-logo_svg__e" d="M.447.363h35.987V42.5H.447z" />
        <path id="reach-logo_svg__g" d="M.213.312h48.12v62.942H.213z" />
        <path id="reach-logo_svg__i" d="M0 .494h24.352v41.09H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(36.667 22.189)">
          <mask id="reach-logo_svg__b" fill="#fff">
            <use xlinkHref="#reach-logo_svg__a" />
          </mask>
          <path
            d="M34.737 29.12l1.495.83C32.825 38.178 26.26 42.5 18.115 42.5 6.147 42.5.245 33.192.245 22.886.245 11.665 7.144.362 20.275.362c7.314 0 16.373 3.492 16.373 17.87H13.626a1.66 1.66 0 00-1.662 1.662c0 12.134 5.07 17.37 11.553 17.37 5.236 0 8.976-3.407 11.22-8.143M12.13 16.07h7.065c3.988 0 6.4-.915 6.4-5.652 0-3.407-1.413-8.228-5.32-8.228-4.322 0-7.396 5.9-8.145 13.88"
            fill="#f9c910"
            mask="url(#reach-logo_svg__b)"
          />
        </g>
        <g transform="translate(75 22.189)">
          <mask id="reach-logo_svg__d" fill="#fff">
            <use xlinkHref="#reach-logo_svg__c" />
          </mask>
          <path
            d="M42.25 38.427c-2.577 2.577-5.984 4.073-9.307 4.073-4 0-7.73-2.494-7.73-7.48-3.076 4.322-7.48 7.48-13.298 7.48-5.567 0-11.22-2.742-11.22-10.804 0-7.223 4.448-12.073 20.84-14.9 1.733-.3 3.012-1.792 3.012-3.552 0-8.3-2.908-10.887-7.314-11.635-1.62 7.243-6.296 10.553-10.09 10.382C3.92 11.845 1.7 8.453 3.298 5.65 5.346 2.06 12.218.362 18.564.362c10.388 0 16.872 3.907 16.872 12.136v21.857c0 2.992.998 4.238 2.742 4.238 1.08 0 2.16-.58 3.24-1.33l.83 1.163zm-17.702-4.82V17.898c-9.142 1.496-11.303 7.065-11.303 11.968 0 4.986 2.244 7.314 4.987 7.314 2.078 0 4.488-1.247 6.317-3.575z"
            fill="#f9c910"
            mask="url(#reach-logo_svg__d)"
          />
        </g>
        <g transform="translate(115.833 22.189)">
          <mask id="reach-logo_svg__f" fill="#fff">
            <use xlinkHref="#reach-logo_svg__e" />
          </mask>
          <path
            d="M36.433 29.95C33.027 38.178 26.46 42.5 18.316 42.5 6.348 42.5.447 33.19.447 22.968.447 11.914 7.345.363 22.388.363c7.73 0 13.88 3.408 13.88 7.563 0 2.494-2.078 3.99-4.904 3.99-3.823 0-9.142-3.325-10.138-10.388-6.068 2.66-8.56 10.388-8.56 18.866 0 10.97 4.238 16.87 11.054 16.87 5.235 0 8.976-3.407 11.22-8.143l1.495.83z"
            fill="#f9c910"
            mask="url(#reach-logo_svg__f)"
          />
        </g>
        <g transform="translate(151.667 .522)">
          <mask id="reach-logo_svg__h" fill="#fff">
            <use xlinkHref="#reach-logo_svg__g" />
          </mask>
          <path
            d="M47.086 61.508c-2.908 0-4.322-1.33-4.322-4.322V33.5c0-7.896-5.152-11.47-11.22-11.47-5.984 0-11.054 3.574-13.712 8.394V7.6c0-4.864-4.683-8.357-9.345-6.972L.213 3.076v1.497H1.2c3.657 0 4.987 1.58 4.987 5.9v46.712c0 2.992-1.413 4.322-4.24 4.322H.63v1.746h21.276v-1.746H20.9a3.07 3.07 0 01-3.067-3.068V32.5c1.994-2.824 5.402-4.238 8.06-4.238 2.992 0 5.237 1.994 5.237 6.15v23.282a5.56 5.56 0 005.558 5.559h11.646v-1.746h-1.248z"
            fill="#f9c910"
            mask="url(#reach-logo_svg__h)"
          />
        </g>
        <path
          d="M19.134 31.142c0-4.744 3.94-8.592 8.802-8.592s8.803 3.848 8.803 8.592H19.134z"
          fill="#f9c910"
        />
        <g transform="translate(0 22.189)">
          <mask id="reach-logo_svg__j" fill="#fff">
            <use xlinkHref="#reach-logo_svg__i" />
          </mask>
          <path
            d="M17.62 35.517c0 2.993 1.413 4.323 4.238 4.323h2.494v1.745H.415V39.84h1.33c2.826 0 4.24-1.33 4.24-4.323v-24.9c0-4.322-1.33-5.902-4.987-5.902H0v-1.58L7.577.834C12.575-.683 17.62 3.057 17.62 8.28v27.238z"
            fill="#f9c910"
            mask="url(#reach-logo_svg__j)"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgReachLogo;
