import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconStar = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M8 1.765L6.036 5.947l-4.48.685L4.8 9.958l-.756 4.638L8 12.41l3.955 2.187-.756-4.638 3.245-3.326-4.48-.685L8 1.765z"
        stroke="#bf8e47"
        strokeWidth={1.5}
        fill="none"
      />
    </svg>
  );
});
export default SvgIconStar;
