import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLeftQuote = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}>
      <path
        d="M17.8 10.4l-3.7 2.3c-.9.6-1.9 1.5-2.9 2.5-.9 1-1.6 2-2.1 3.1s-.8 2.5-1 4.1h2.3c2 0 3.6.5 4.8 1.5s1.7 2.5 1.7 4.4c0 1.4-.5 2.6-1.5 3.8s-2.4 1.8-4.2 1.8c-2.8 0-4.8-.9-6-2.8-1.2-1.8-1.8-4.2-1.8-7.2 0-2.1.5-4 1.4-5.8.9-1.7 2-3.3 3.3-4.7s2.8-2.6 4.2-3.6l3.8-2.4 1.7 3zm18.1 0l-3.7 2.3c-.9.6-1.9 1.5-2.9 2.5-.9 1-1.7 2-2.1 3.1s-.8 2.4-1 4.1h2.3c2 0 3.6.5 4.8 1.5s1.7 2.5 1.7 4.4c0 1.4-.5 2.6-1.5 3.8s-2.4 1.8-4.2 1.8c-2.8 0-4.8-.9-6-2.8-1.2-1.8-1.8-4.2-1.8-7.2 0-2.1.5-4 1.4-5.8.9-1.7 2-3.3 3.3-4.7s2.8-2.6 4.2-3.6l3.8-2.4 1.7 3z"
        fill={color}
      />
    </svg>
  );
});
export default SvgLeftQuote;
