import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgUserSmiling = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 48h48V0H0z" />
        <g
          stroke="#1C1C1C"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}>
          <path d="M24 43c3.616 0 12.054-4.75 12.054-11.875 3.616 0 3.616-7.125 0-7.125l-1.206-5.937c-4.337 0-5.035-2.625-4.848-4.75-2.083 1.405-7.206 4.75-16.849 4.75L11.946 24c-3.616 0-3.616 7.125 0 7.125C11.946 38.25 20.384 43 24 43z" />
          <path d="M38.464 25.188l1.206-8.313S40.875 5 24 5C7.124 5 8.33 16.875 8.33 16.875l1.205 8.313" />
          <path d="M16.768 26.375c0-1.312 1.08-1.187 2.41-1.187 1.332 0 2.412-.125 2.412 1.187m4.82 0c0-1.312 1.08-1.187 2.412-1.187 1.33 0 2.41-.125 2.41 1.187m-12.054 6.897c0 .904 1.502 1.645 3.338 1.645h2.967c1.836 0 3.338-.741 3.338-1.645" />
        </g>
      </g>
    </svg>
  );
});
export default SvgUserSmiling;
