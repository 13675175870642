import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgGiftCard = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}>
      <path
        d="M8.38 9.883c.453 0 .848-.058 1.423-.237v-.992h-1.66V7.85h2.796v2.422c-.74.317-1.876.46-2.63.46-1.933 0-3.248-.862-3.248-2.745 0-1.553 1.1-2.717 3.14-2.717 1.567 0 2.74.596 2.74 1.81H9.732c-.036-.726-.783-1.006-1.358-1.006-1.093 0-2.106.553-2.106 1.905 0 .963.517 1.904 2.113 1.904M15.276 2.5H.724c-.4 0-.724.338-.724.754v9.492c0 .416.324.754.724.754h14.552c.4 0 .724-.338.724-.754V3.254c0-.416-.324-.754-.724-.754"
        fill="#1b7b76"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgGiftCard;
