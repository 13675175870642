import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconBell = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={color}
      fillRule="evenodd"
      opacity={0.7}
      {...props}>
      <path d="M15.86 18.498H2l2.168-2.743a.44.44 0 00.094-.285V7.004C4.26 3.794 6.67.994 10.1.2a8.725 8.725 0 012.84-.16l.947.157a7.953 7.953 0 013.57 1.85c1.46 1.306 2.277 3.1 2.27 4.948v8.476a.44.44 0 00.096.287L22 18.5l-6.14-.012zM10.024 20c.12 1.123.955 2 1.976 2s1.856-.877 1.976-2h1.998c-.135 2.227-1.856 4-3.974 4s-3.84-1.773-3.974-4h1.998z" />
    </svg>
  );
});
export default SvgIconBell;
