import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgTip = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke={color} cx={10} cy={10} r={9.5} />
        <text
          fontFamily="UniversLTStd, Univers LT Std"
          fontSize={12}
          fill={color}>
          <tspan x={7} y={9}>
            {'?'}
          </tspan>
        </text>
      </g>
    </svg>
  );
});
export default SvgTip;
