import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgTrashRound = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}>
      {'fill-rule=&quot;evenodd&quot;&gt;'}
      <path
        d="M32 16c0 8.836-7.164 16-16 16S0 24.837 0 16 7.164 0 16 0s16 7.163 16 16"
        fill="#fff"
      />
      <path
        d="M20.05 23.242h-8.103L10.84 12.34h10.317L20.05 23.242zM16 8.76c.966 0 1.752.71 1.752 1.58h-3.505c0-.87.786-1.58 1.753-1.58zm7.707 1.58h-3.955c0-1.974-1.683-3.58-3.752-3.58s-3.753 1.606-3.753 3.58H8.292a1 1 0 100 2h.538l1.22 12.004a1 1 0 00.995.899h9.91a1 1 0 00.995-.898l1.22-12.005h.538a1 1 0 100-2z"
        fill={color}
      />
    </svg>
  );
});
export default SvgTrashRound;
