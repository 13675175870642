import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgInfo = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={8} cy={8} r={8} fill="#8F8E8B" opacity={0.5} />
        <path fill="#FFF" d="M7 12.5h2v-6H7v6zm0-7h2v-2H7v2z" />
      </g>
    </svg>
  );
});
export default SvgInfo;
