import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgArrowDown = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17 21"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 21h15V3.188H0z" />
        <path
          stroke={color}
          d="M8.501 19.5L8.106.801M15.86 13.276l-7.329 6.66-7.331-6.66"
        />
      </g>
    </svg>
  );
});
export default SvgArrowDown;
