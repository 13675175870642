import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgRay = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 10 16"
      {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.503 6.149c.482 0 .641.361.355.803l-5.681 8.761c-.287.442-.433.365-.325-.171l.951-4.716c.109-.536-.197-.975-.679-.975H.497c-.482 0-.641-.36-.354-.803L5.823.287c.287-.442.433-.365.325.17l-.951 4.717c-.108.536.197.975.678.975h3.628z"
      />
    </svg>
  );
});
export default SvgRay;
