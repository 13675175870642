import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgCheck = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.286 9L0 4.681l1.209-1.217 3.077 3.092L10.79 0 12 1.226z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
});
export default SvgCheck;
