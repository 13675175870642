import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgEmail = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M.018 5.978A1.75 1.75 0 000 6.231V17.77a1.74 1.74 0 001.756 1.73h20.488A1.74 1.74 0 0024 17.769V6.23a1.75 1.75 0 00-.018-.252l-10.152 8.5a2.883 2.883 0 01-3.677 0L.018 5.978zM1.756 4.5a1.8 1.8 0 00-.65.126l10.19 8.545c.446.374.943.375 1.4 0l10.198-8.555a1.783 1.783 0 00-.64-.117H1.756z"
        fill="#1b7b76"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgEmail;
