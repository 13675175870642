import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgFacebookTransparent = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 12 20"
      {...props}>
      <path
        fill="#39579A"
        d="M3.514 20v-9.325H.167V7.239h3.347V4.7C3.514 1.658 5.458 0 8.297 0c1.36 0 2.529.097 2.87.14v3.18h-1.97c-1.544 0-1.843.702-1.843 1.732v2.187h3.594l-.513 3.436h-3.08V20H3.513z"
      />
    </svg>
  );
});
export default SvgFacebookTransparent;
