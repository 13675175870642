import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgPinterestColored = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12 0C5.374 0 0 5.372 0 12c0 4.912 2.955 9.135 7.184 11-.034-.837-.006-1.844.207-2.756l1.544-6.538s-.382-.766-.382-1.9c0-1.778 1.032-3.106 2.314-3.106 1.09 0 1.62.82 1.62 1.802 0 1.096-.7 2.738-1.06 4.258-.3 1.274.638 2.312 1.894 2.312 2.273 0 3.805-2.92 3.805-6.38 0-2.63-1.77-4.598-4.993-4.598-3.64 0-5.907 2.714-5.907 5.745 0 1.047.308 1.784.8 2.354.223.264.253.368.172.67l-.244.962a.41.41 0 01-.6.301c-1.678-.684-2.458-2.52-2.458-4.585 0-3.408 2.875-7.498 8.576-7.498 4.582 0 7.598 3.318 7.598 6.875 0 4.708-2.616 8.224-6.476 8.224-1.294 0-2.513-.7-2.93-1.494l-.844 3.298c-.254.924-.752 1.85-1.208 2.57a11.98 11.98 0 003.401.493c6.627 0 12-5.372 12-12S18.628 0 12 0"
        fill="#cc2127"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgPinterestColored;
