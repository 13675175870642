import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgBulb = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g
        stroke={color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M6.678 16.205h4.643M14.107 10.15c0-3.025-2.61-5.438-5.695-5.093a5.126 5.126 0 00-1.734 9.652v1.961C6.678 17.957 7.718 19 9 19a2.325 2.325 0 002.321-2.33v-1.96a5.12 5.12 0 002.786-4.56zM.643 9.385h1.671M3.09 3.456l1.182 1.186M9 1v1.677M14.909 3.456l-1.182 1.186M17.357 9.385h-1.671" />
      </g>
    </svg>
  );
});
export default SvgBulb;
