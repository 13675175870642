import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconJobsBondo = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M12.424 4.424a3.576 3.576 0 01.001 7.151l-3.13.001A3.58 3.58 0 015.72 8a3.66 3.66 0 01.15-1.028l.834-.001a1.02 1.02 0 01.683.261 2.02 2.02 0 00-.147.767c0 .55.213 1.065.602 1.454a2.04 2.04 0 001.454.602h3.128A2.04 2.04 0 0014.481 8a2.04 2.04 0 00-.602-1.454 2.04 2.04 0 00-1.454-.602h-1.598c-.22-.438-.5-.842-.867-1.2a4.32 4.32 0 00-.354-.321zm-5.72 0a3.574 3.574 0 013.424 4.606h-.833a1.02 1.02 0 01-.685-.262c.097-.24.148-.5.148-.768 0-.548-.214-1.064-.602-1.453a2.04 2.04 0 00-1.453-.602h-3.13a2.04 2.04 0 00-1.454.602A2.04 2.04 0 001.521 8c0 .55.214 1.065.602 1.454s.905.602 1.454.602l1.6-.001a4.6 4.6 0 00.873 1.217l.334.304H3.575C1.6 11.575.001 9.975.001 8s1.6-3.576 3.575-3.575z"
        fill="#1c1c1c"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconJobsBondo;
