import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgHeart = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M8 15.377s8-5.478 8-10.66c0-5.185-7.098-5.584-8-.4-.902-5.184-8-4.785-8 .797 0 5.583 8 10.263 8 10.263z"
      />
    </svg>
  );
});
export default SvgHeart;
