import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgCheckReverse = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 6"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path id="check-reverse_svg__a" d="M0 0h10v6H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="check-reverse_svg__b" fill="#fff">
          <use xlinkHref="#check-reverse_svg__a" />
        </mask>
        <path
          d="M1.55 4.55V3.384h5.833V-2.45H8.55v7h-7z"
          fill={color}
          mask="url(#check-reverse_svg__b)"
          transform="rotate(45 5.05 1.05)"
        />
      </g>
    </svg>
  );
});
export default SvgCheckReverse;
