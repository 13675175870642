import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgCart = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="4 2 16 20"
      fill={color}
      fillRule="evenodd"
      opacity={0.7}
      {...props}>
      <path d="M4.75 22h14.5V7.5H4.75zm5.274-16c.12-1.123.955-2 1.976-2s1.855.877 1.976 2h1.998C15.84 3.773 14.118 2 12 2S8.16 3.773 8.025 6h2z" />
    </svg>
  );
});
export default SvgCart;
