import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgStarOutlined = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.5 1.68L6.369 5.953l-4.745.682 3.433 3.312-.812 4.688L8.5 12.421l4.255 2.214-.812-4.688 3.433-3.312-4.746-.682L8.5 1.68z"
        stroke={color}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgStarOutlined;
