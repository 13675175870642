import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgCalifornia = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}>
      <path
        d="M10.7 16c-.002-.133-.124-.206-.166-.538-.04-.628-.994-1.508-1.243-1.465s-.34.058-.33-.016.116-.476-.384-.448-.722-.12-.927-.466-.46-.056-.59-.18-.564-.1-.872-.076-.292-.287-.275-.4-.11-.127-.05-.357.043-.455-.04-.46-.335.01-.225-.2-.272-.408-.33-.478-.313-.256-.354-.444-.176-.077-.24-.385-.368-.2-.41-.464-.304-.402.04-.426-.192-.597-.46-.835-.143-.357-.278-.75-.12-.418.093-.153-.32-.546-.64-.77.12-.1.063-.18-.156-.235-.18-.335-.21-.266-.21-.266c-.27-.194.106.065-.4-.473s-.047-.348-.156-.568c-.22-.415-.116-.62-.116-.62.272-.468-.394-1.153-.553-1.3s-.1-.284-.13-.388.322-.697.4-.892-.073-.188-.033-.247c.24-.48.087-.796.066-.966S1.597.48 1.646.36 1.723 0 1.723 0l5.152.22-.05 4.964 7.143 6.65s-.04.18.012.22.05.15.194.225.018.25.16.388.275.213.3.254.056.156-.115.26-.284.045-.24.225-.085.272-.097.44-.005.604-.188.613-.052.213-.03.23.058.194-.01.312.08.116.15.104.16.006.172.087.07.27-.05.34-.258.086-.258.086L10.7 16z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgCalifornia;
