import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgStar = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.734 0L6.098 5.266l-5.894.845 4.266 4.1L3.463 16l5.271-2.734L14.006 16 13 10.211l4.265-4.099-5.895-.846L8.735 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgStar;
