import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgDiscover = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 26 18"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={25}
          height={17}
          x={0.5}
          y={0.5}
          fill="#F4F4F4"
          fillRule="nonzero"
          stroke="#E4E5E6"
          rx={2}
        />
        <path
          fill="#E77600"
          d="M13.625 7.015c-1.032 0-1.87.876-1.87 1.956 0 1.149.802 2.008 1.87 2.008 1.044 0 1.866-.87 1.866-1.985 0-1.11-.817-1.98-1.866-1.98"
        />
        <path
          fill="#1C1C1C"
          d="M3.804 9.946c-.215.21-.494.302-.936.302h-.184V7.731h.184c.442 0 .71.085.936.308.237.228.38.582.38.948 0 .365-.143.73-.38.959m-.8-2.86H2v3.807h1c.53 0 .915-.137 1.252-.44.4-.36.636-.901.636-1.461 0-1.124-.773-1.906-1.883-1.906M5.204 10.893h.684V7.086h-.684zM7.56 8.547c-.41-.165-.531-.274-.531-.48 0-.24.216-.422.51-.422.206 0 .374.092.553.308l.358-.508a1.47 1.47 0 00-1.031-.422 1.07 1.07 0 00-1.095 1.09c0 .526.221.794.863 1.045.268.103.405.171.473.217a.466.466 0 01.206.394.52.52 0 01-.532.536c-.326 0-.589-.176-.747-.508l-.442.463c.316.502.695.725 1.215.725.71 0 1.21-.514 1.21-1.25 0-.606-.231-.88-1.01-1.188zm1.226.445c0 1.12.81 1.987 1.852 1.987.294 0 .547-.063.857-.223v-.873c-.273.296-.515.416-.825.416-.69 0-1.179-.542-1.179-1.312 0-.731.505-1.308 1.147-1.308.326 0 .573.126.857.429v-.874c-.3-.165-.547-.234-.841-.234-1.037 0-1.868.885-1.868 1.992M17.04 9.643l-.937-2.557h-.747l1.49 3.904h.367l1.516-3.904h-.742zM19.039 10.893h1.941v-.645h-1.257V9.221h1.21v-.645h-1.21v-.845h1.257v-.645h-1.941zM22.317 8.838h-.2V7.685h.21c.426 0 .658.194.658.565 0 .383-.232.588-.668.588zm.573.451c.515-.114.799-.496.799-1.079 0-.713-.452-1.124-1.24-1.124h-1.016v3.807h.683v-1.53h.09l.947 1.53h.842L22.89 9.289z"
        />
      </g>
    </svg>
  );
});
export default SvgDiscover;
