import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgPlusCircle = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={19}
          height={19}
          x={0.5}
          y={0.5}
          stroke="#E4E5E6"
          rx={9.5}
        />
        <path
          fill={color}
          d="M13.375 8.875h-2.25v-2.25a1.125 1.125 0 10-2.25 0v2.25h-2.25a1.125 1.125 0 100 2.25h2.25v2.25a1.125 1.125 0 102.25 0v-2.25h2.25a1.125 1.125 0 100-2.25"
        />
      </g>
    </svg>
  );
});
export default SvgPlusCircle;
