import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgBell = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g stroke={color} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M23 18.674c-1.586 0-2.87-1.248-2.87-2.79v-5.117c0-3.396-2.208-6.283-5.3-7.403C14.62 2.027 13.436 1 12 1S9.377 2.026 9.166 3.364C6.075 4.484 3.87 7.37 3.87 10.768v5.115c0 1.543-1.286 2.791-2.87 2.791v1.535h22v-1.535zM14.87 20.21c0 1.54-1.286 2.79-2.87 2.79-1.586 0-2.87-1.25-2.87-2.79" />
      </g>
    </svg>
  );
});
export default SvgBell;
