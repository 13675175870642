import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgYotpo = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 66"
      width="1em"
      height="1em"
      {...props}>
      <circle cx={33} cy={33} r={33} fill={color} />
      <g fill="#fff">
        <path
          d="M14.5 19.1h3.9l3 5.5 2.9-5.5H28l-4.9 8.8v4.7h-3.6V28z"
          fillRule="evenodd"
        />
        <path d="M26.9 25.9c0-4.1 3.3-6.9 7.1-6.9s7.1 2.9 7.1 6.9c0 4.1-3.3 7-7.1 7-3.8-.1-7.1-2.9-7.1-7m10.5 0c0-2.1-1.3-3.8-3.5-3.8s-3.5 1.7-3.5 3.8 1.3 3.8 3.5 3.8c2.3 0 3.5-1.7 3.5-3.8" />
        <path d="M44.4 22.1h-3.5v-3h10.6v3H48v10.5h-3.6z" fillRule="evenodd" />
        <path d="M19.1 47.4h-3.5V33.9h5c3.6 0 5.6 2.3 5.6 5.3s-2 5.3-5.6 5.3h-1.5v2.9zm1.3-6c1.6 0 2.2-.9 2.2-2.2s-.6-2.3-2.2-2.3h-1.3v4.5h1.3zm6.4-.7c0-4.1 3.3-6.9 7.1-6.9s7.1 2.9 7.1 6.9c0 4.1-3.3 7-7.1 7-3.8-.1-7.1-2.9-7.1-7m10.6 0c0-2.1-1.3-3.8-3.5-3.8s-3.5 1.7-3.5 3.8 1.3 3.8 3.5 3.8 3.5-1.7 3.5-3.8" />
        <path
          d="M41 45.7c0-1.2.9-2 1.9-2s1.9.8 1.9 2-.9 2-1.9 2c-1-.1-1.9-.9-1.9-2"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
});
export default SvgYotpo;
