import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconJobsPrint = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M16 15v1H0v-1h16zm0-2.5v1H0v-1h16zm0-2.5v1H0v-1h16zm0-2.5v1H0v-1h16zM16 5v1H0V5h16zm0-2.5v1H0v-1h16zM16 0v1H0V0h16z"
        fill="#1c1c1c"
      />
    </svg>
  );
});
export default SvgIconJobsPrint;
