import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgClose = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g stroke={color} fill="none" fillRule="evenodd" strokeLinecap="square">
        <path d="M12.76.24L1.24 11.76M12.76 11.76L1.24.24" />
      </g>
    </svg>
  );
});
export default SvgClose;
