import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconStarFilled = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        fill="#bf8e47"
        d="M8 0L5.528 5.266 0 6.11l4 4.1L3.056 16 8 13.266 12.944 16 12 10.21l4-4.1-5.528-.845L8 0z"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconStarFilled;
