import { IconProps } from './icon.props';

export const IconPresets: Partial<IconProps> = {
  size: 'unset',

  opacity: 1,

  color: 'grey',

  strokeColor: 'primary',

  strokeWidth: '10',

  cursor: 'unset',
};
