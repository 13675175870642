import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLoader = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle
        cx={200}
        cy={200}
        r={160}
        fill="none"
        stroke="#D6D4D2"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={200}
        cy={200}
        r={160}
        stroke={color}
        fill="none"
        strokeWidth={strokeWidth}
        strokeDasharray={1000}
        strokeDashoffset={1000}
        transform="rotate(-90 200 200)">
        <animate
          attributeName="stroke-dashoffset"
          values="1000;-1000"
          dur="2s"
          repeatCount="indefinite"
          calcMode="spline"
          keySplines=".5 .16 .13 .5"
        />
      </circle>
    </svg>
  );
});
export default SvgLoader;
