import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgSearch = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={color}
      fillRule="evenodd"
      opacity={0.7}
      {...props}>
      <path d="M16 9A7 7 0 112 9a7 7 0 1114 0m4.586 13l-5.35-5.35 1.414-1.414 5.35 5.35z" />
    </svg>
  );
});
export default SvgSearch;
