import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgSun = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}>
      <path
        d="M8.64 13.36c.17 0 .27.152.202.303l-.64 2.174c-.05.22-.37.22-.438 0l-.64-2.174a.232.232 0 01.22-.303zm-4.23-.876c.034-.152.202-.236.337-.152l1.13.64c.135.067.152.253.05.37l-1.65 1.567c-.17.17-.438 0-.388-.22zm6.825-.17c.135-.067.32 0 .337.152l.522 2.207c.05.22-.22.37-.388.22l-1.65-1.567a.242.242 0 01.05-.37zM8 3.737a4.263 4.263 0 11-.001 8.527A4.263 4.263 0 018 3.737zm-5.342 6.336c.1-.1.286-.084.37.05l.64 1.13c.067.135 0 .303-.152.337l-2.207.522c-.22.05-.37-.22-.22-.388zm10.313.05c.084-.135.27-.17.37-.05l1.567 1.65c.17.17 0 .438-.22.388l-2.208-.523c-.15-.034-.236-.202-.15-.337zm.634-2.994l.057.01 2.174.64c.22.067.22.37 0 .455l-2.174.64a.232.232 0 01-.303-.22V7.36c0-.152.152-.27.303-.22zM2.338 7.124a.232.232 0 01.303.22V8.64c0 .17-.152.27-.303.22L.164 8.22c-.22-.05-.22-.37 0-.438zm-1.07-3.24l.058.006 2.207.522c.152.034.22.22.152.337l-.64 1.13c-.084.135-.27.17-.37.05l-1.567-1.65c-.17-.17 0-.438.22-.388zm13.42.006c.22-.05.37.22.22.388l-1.567 1.65c-.1.1-.286.084-.37-.05l-.64-1.13c-.067-.135 0-.303.152-.337zM3.905 1.327c-.05-.22.22-.37.388-.22l1.65 1.567c.1.1.084.286-.05.37l-1.13.64c-.135.067-.32 0-.337-.152zm7.82-.236c.17-.17.438 0 .388.22l-.523 2.207c-.034.152-.202.236-.337.152l-1.13-.64c-.135-.067-.15-.253-.05-.37zM7.78.164c.05-.22.37-.22.438 0l.657 2.174a.232.232 0 01-.22.303H7.36a.225.225 0 01-.22-.303z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgSun;
