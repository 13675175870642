import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconInfo = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M7 5.5h2v-2H7v2zm0 7h2v-6H7v6zM8 0a8 8 0 100 16A8 8 0 108 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconInfo;
