import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgNext = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fillRule="evenodd"
      {...props}>
      <circle fill={color} fillRule="nonzero" cx={16} cy={16} r={16} />
      <path
        fill="#fff"
        d="M12.604 22.638L14.068 24l7.298-7.842-7.298-7.84-1.464 1.363 6.03 6.477z"
      />
    </svg>
  );
});
export default SvgNext;
