import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgWorld = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}>
        <path d="M23 11.959C23 18.058 18.062 23 11.969 23 5.877 23 .999 17.858.999 11.76 1 5.823 5.659 1.247 11.528 1.01c.159-.007.318-.01.478-.01C18.097 1 23 5.86 23 11.96zM11.527 1.009c-5.74 6.218-5.74 14.33 0 21.981m.956-21.98c5.739 6.217 5.739 14.325 0 21.978M2.694 15.666h18.672M2.534 8.333h18.832" />
      </g>
    </svg>
  );
});
export default SvgWorld;
