import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgGuarantee = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 54 62"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-13 71h80V-9h-80z" />
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}>
          <path d="M1.153 1h51.693v18.95C52.846 37.175 42.895 52.98 27 61 11.105 52.98 1.153 37.175 1.153 19.95V1zM1.153 8.826h51.693" />
          <path d="M37.833 23.387L24.084 35.282l-4.585-4.249" />
        </g>
      </g>
    </svg>
  );
});
export default SvgGuarantee;
