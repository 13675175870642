import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgTrash = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g stroke={color} strokeWidth={1.004} fill="none" fillRule="evenodd">
        <path d="M6.23 3.839h0c0-1.425 1.233-2.58 2.753-2.58h0c1.52 0 2.752 1.155 2.752 2.58h0" />
        <path
          strokeLinecap="square"
          d="M15.039 5.903l-1.101 10.839h-9.91L2.927 5.903M16.69 3.839H1.275"
        />
      </g>
    </svg>
  );
});
export default SvgTrash;
