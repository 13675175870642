import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgAr = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12.474 11.345v2.55a.475.475 0 01-.948 0v-2.55l-2.58-1.3c-.234-.117-.33-.402-.21-.636s.402-.33.636-.21L12 10.523l2.63-1.315a.475.475 0 01.426.846l-2.58 1.3zm-.254 9.6a.463.463 0 01-.44 0L8.946 19.53a.474.474 0 11.426-.846l2.156 1.078v-2.55a.475.475 0 01.948 0v2.55l2.156-1.078a.475.475 0 01.426.846l-2.835 1.416zm-.44-17.89a.466.466 0 01.44-.001l2.835 1.417c.234.117.328.4.21.636s-.402.33-.636.21L12.474 4.24v2.55a.475.475 0 01-.948 0V4.24L9.37 5.318a.474.474 0 01-.426-.846l2.835-1.417zM4.895 8.03v2.55a.475.475 0 01-.948 0V7.273a.46.46 0 01.139-.343c.04-.039.08-.07.128-.092L7.05 5.42a.474.474 0 01.426.846l-1.995.998 1.995.998c.234.117.33.4.21.636s-.402.33-.636.21L4.895 8.03zm.585 8.707l1.995.998c.234.117.33.4.21.636s-.402.328-.636.21l-2.837-1.42a.47.47 0 01-.216-.213l-.02-.045a.49.49 0 01-.03-.176V13.42a.475.475 0 01.948 0v2.55l2.156-1.078a.474.474 0 01.426.846l-1.995.998zm14.572-.01a.46.46 0 01-.138.343.45.45 0 01-.128.092l-2.837 1.42a.475.475 0 01-.426-.846l1.995-.998-1.995-.998c-.234-.117-.328-.402-.21-.636s.402-.33.636-.21l2.156 1.078v-2.55a.475.475 0 01.948 0v3.306zm-.266-9.89c.1.045.168.117.216.214l.02.045a.49.49 0 01.03.176v3.306a.475.475 0 01-.948 0V8.03L16.95 9.108a.475.475 0 01-.426-.846l1.995-.998-1.995-.998c-.234-.117-.328-.402-.21-.636s.402-.33.636-.21l2.837 1.418z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgAr;
