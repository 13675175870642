import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgHouse = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}>
        <path d="M41.348 20.023v22.161H6.652V20.023" />
        <path d="M10.304 36.729h14.609v-10.91H10.304zM28.566 42.184h9.13V25.819h-9.13zM41.348 5.816H6.652L3 13.089h42zM45 13.09v6.933H3V13.09" />
        <path d="M34.044 34.001a.91.91 0 100 1.818.91.91 0 00.913-.909.911.911 0 00-.913-.909z" />
      </g>
    </svg>
  );
});
export default SvgHouse;
