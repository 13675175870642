import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgDrag = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 56 53"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M44 24.5C44 33.614 36.836 41 28 41c-8.837 0-16-7.387-16-16.5S19.163 8 28 8c11.314 0 16 4.833 16 16.5z" />
        <path
          stroke={color}
          strokeWidth={1.333}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M30.777 32.063l6.371-7.562-6.37-7.563M25.223 32.063l-6.37-7.562 6.37-7.563"
        />
      </g>
    </svg>
  );
});
export default SvgDrag;
