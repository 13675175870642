import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLeaf = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}>
      <path
        d="M15.833 2.645C11.727.202 7.608.01 3.918 2.05 1.807 3.208.323 5.126.048 7.096A4.514 4.514 0 00.97 10.55a12.353 12.353 0 015.04-5.033.32.32 0 01.442.098.32.32 0 01-.098.442l-.03.017C2.146 8.4.784 11.9.01 15.027c0 .07.022.137.064.192.122.153.333.122.723.128h.69c.697 0 .704-.045.748-.27.193-.964.494-1.904.895-2.8 3.108 1.37 7.374-.64 9.16-2.79a9.25 9.25 0 001.567-3.083c.435-1.2.85-2.34 2.002-3.198a.32.32 0 000-.531l-.026-.032z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgLeaf;
