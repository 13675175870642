import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconJobsAssemble = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M10.755 12.168l2.146 2.146C11.546 15.367 9.85 16 8 16s-3.546-.633-4.9-1.686l2.146-2.145A4.98 4.98 0 008.001 13c1.018 0 1.964-.31 2.754-.832zm3.56-9.068a7.962 7.962 0 01-.001 9.8l-2.146-2.146a4.97 4.97 0 000-5.508zm-12.628 0l2.146 2.146C3.308 6.036 3 6.98 3 8s.308 1.965.832 2.755L1.686 12.9A7.95 7.95 0 010 8a7.95 7.95 0 011.686-4.9zM8 5a3 3 0 010 6 3 3 0 010-6zm0-5a7.95 7.95 0 014.9 1.686l-2.145 2.146a4.98 4.98 0 00-5.509 0L3.1 1.686A7.957 7.957 0 018 0z"
        fill="#1c1c1c"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconJobsAssemble;
