import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgIconUpload = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M8.066 4.27l5.282 5.282-1.414 1.414L9.066 8.1V16h-2V8.1l-2.868 2.868-1.414-1.414L8.066 4.27zM16 .001v2H0v-2h16z"
        fill="#1b7b76"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgIconUpload;
