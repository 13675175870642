import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgCalendar = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path id="calendar_svg__a" d="M0 20h20V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          stroke={color}
          strokeLinejoin="round"
          d="M4.13 2.566H1V19h18V2.566h-3.13"
        />
        <mask id="calendar_svg__b" fill="#fff">
          <use xlinkHref="#calendar_svg__a" />
        </mask>
        <path
          stroke={color}
          strokeLinejoin="round"
          mask="url(#calendar_svg__b)"
          d="M4.131 4.13h2.348V1H4.131zM13.521 4.13h2.348V1h-2.348z"
        />
        <path
          stroke={color}
          strokeLinejoin="round"
          d="M6.479 2.566h7.042M1 6.479h18"
        />
        <path
          stroke={color}
          strokeWidth={0.5}
          strokeLinejoin="round"
          d="M5.695 8.043v9.392M10 8.043v9.392M14.305 8.043v9.392M2.566 9.608h14.868M2.566 12.739h14.868M2.566 15.869h14.868"
        />
      </g>
    </svg>
  );
});
export default SvgCalendar;
