import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgLamp = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M10.712 13.487C10.63 14.886 9.453 16 8 16s-2.628-1.114-2.71-2.513zM7.31.038C10.932-.358 14 2.404 14 5.867c0 2.278-1.332 4.25-3.273 5.22v1.025H5.273v-1.025c-2.202-1.102-3.62-3.5-3.2-6.154C2.48 2.363 4.666.327 7.31.038z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgLamp;
