import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgPlusCircleBold = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
      <path
        d="M8 0a8.001 8.001 0 010 16A8 8 0 118 0zm0 2a6.01 6.01 0 00-6 6 6.01 6.01 0 006 6 6.01 6.01 0 006-6 6.01 6.01 0 00-6-6zm0 2a1 1 0 011 1v2h2a1 1 0 110 2H9v2a1 1 0 11-2 0V9H5a1 1 0 110-2h2V5a1 1 0 011-1z"
        fill="#1b7b76"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgPlusCircleBold;
