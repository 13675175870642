import React, { memo } from 'react';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';
import { getThemeColor } from 'styles/theme';
const SvgRuler = memo((iconProps: IconProps) => {
  const ref = React.useRef();
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps, {
    ref,
  });
  const color = getThemeColor(colorProp);
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M7 17v-5.5l5.5 5.5H7zm12.854 2.147l-15-15A.5.5 0 004 4.5v3h.5a.5.5 0 110 1H4v1h.5a.5.5 0 110 1H4v1h.5a.5.5 0 110 1H4v1h.5a.5.5 0 110 1H4v1h.5a.5.5 0 110 1H4v1h.5a.5.5 0 110 1H4v1a.5.5 0 00.5.5h15a.5.5 0 00.354-.854z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgRuler;
